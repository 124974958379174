import { createSelector } from '@ngrx/store';

import { KpiDeviationsStateContainer } from './kpi-deviations.state.model';

export const selectCropsDeviationsState = (state: KpiDeviationsStateContainer) =>
    state.activeCropsKpiDeviations;

export const selectCropsDeviations = createSelector(
    selectCropsDeviationsState,
    (state) => state.cropsKpiDeviations,
);
